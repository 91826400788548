@tailwind base;
@tailwind components;
@tailwind utilities;


/* :root {
  --toggler-width: 120px;
  --toggler-height: 40px;
  --toggler-bg-color: gray;
  --toggler-active-bg-color: #08853c;
  --menu-starting-top: 0px;
  --menu-ending-top: 45px;
  --menu-width: 200px;
  --menu-max-height: 200px;
  --menu-bg-color: #188ddb;
  --fade-from-color: transparent;
  --fade-to-color: black;
  --timeout: 350ms;
} */

/* var(--timeout) */

@layer base {


  .header {
    position: sticky;
    top: 0;
    z-index: 5;
    padding: 0;
  }

  .navbar {
    /*padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 19px;*/
    padding: 0;
    position: absolute;
    z-index: 1;
  }


  .menulink  {
    /* font-size: 20px;
     font-weight: 400*/

    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.6rem;
    padding-left: 1rem;
  }

  .menulink-active {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.6rem;
    padding-left: 1rem;
    font-weight: bold;

  }


  .imglink img {
    /* font-size: 20px;
     font-weight: 400*/
    margin-top: 0.65rem;  
    margin-left: 1rem;  
  }

  .menulink-active {}



  .author-std {
    @apply w-full justify-center flex;
    margin: 0;
    padding-top: 5.1rem;
    padding-bottom: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    font-size: 2.5rem;
    line-height: 5.3rem;
    font-weight: 400;
    background: white;
  }

  .author-big {
    @apply w-full justify-center flex;

    padding-top: 5.1rem;
    font-size: 2.5rem;
    line-height: 6.3rem;
  }

  .author-small {
    @apply w-full justify-center flex;

    padding-top: 0;
    font-size: 1.5rem;
    border-bottom: 0.2rem solid black;
    border-top: 0.2rem solid black;
    line-height: 2.2rem;
  }



  @keyframes logo-tobig {
    0% {
      padding-top: 0;
      font-size: 1.5rem;
      border-bottom: 0.2rem solid black;
      border-top: 0.2rem solid black;
      line-height: 2.2rem;
    }

    30% {
      border-bottom: 0;
      border-top: 0.1rem solid black;
    }

    100% {
      padding-top: 5.1rem;
      font-size: 2.5rem;
      line-height: 6.3rem;
      border-bottom: 0;
      border-top: 0;
    }
  }

  @keyframes logo-tosmall {
    0% {
      padding-top: 5.1rem;
      font-size: 3.5rem;
      line-height: 6.3rem;
      border-bottom: 0;
      border-top: 0;
    }

    80% {
      padding-top: 0;
      font-size: 1.5rem;
      border-bottom: 0;
      border-top: 0.2rem solid black;
      line-height: 2.2rem;
    }

    100% {
      padding-top: 0;
      font-size: 1.5rem;
      border-bottom: 0.2rem solid black;
      border-top: 0.2rem solid black;
      line-height: 2.2rem;
    }
  }

  @keyframes filter-tosmall {
    0% {
      top: 6.4rem;
    }

    100% {
      top: 2.45rem;
    }
  }


  @keyframes filter-tobig {
    0% {
      top: 2.45rem;
    }

    100% {
      top: 6.4rem;
    }
  }

  @keyframes menu-tosmall {

    0% {
      scale: 1 1;
    }

    70% {
      scale: 1 .3;
    }

    90% {
      transform: scale(0.5, .3);
    }

    100% {
      transform: scale(0, 0);
    }
  }


  @keyframes menu-tobig {
    0% {
      transform: scale(0, .025);
    }
    30% {
      transform: scale(1, .3);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .author-big {
      animation-name: logo-tobig;
      animation-duration: .3s;
      animation-iteration-count: 1;
    }

    .author-small {
      animation-name: logo-tosmall;
      animation-duration: .2s;
      animation-iteration-count: 1;
    }


    .filter-big {
      animation-name: filter-tobig;
      animation-duration: .3s;
      animation-iteration-count: 1;
    }

    .filter-small {
      animation-name: filter-tosmall;
      animation-duration: .2s;
      animation-iteration-count: 1;
    }

    .menu-visible {
      animation-name: menu-tobig;
      animation-duration: .05s;
      animation-iteration-count: 1;
    }

    .menu-invisible {
      animation-name: menu-tosmall;
      animation-duration: .1s;
      animation-iteration-count: 1;
    }
  }

}
