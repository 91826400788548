@tailwind base;
@tailwind components;
@tailwind utilities;


.container {
    position: absolute;
}

.toggler {}

.toggler--active {}




.list-item {}




@layer base {


    .btn-container {
        width: 100%;

        text-align: right;
    }

    .btn-filter {
        position: absolute;

        right: 8.4%;
        top: 10.3rem;
        height: 2.6rem;
        z-index: 7;
    }

    @media only screen and (max-width: 1024px) {
        
        .btn-filter {
            
            top: 5.3rem;
        }
    }

    .list {
        /*margin: 0 auto;
        border: 1px solid green;*/        
        display: block;
        position: absolute;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        
        /*border-bottom: 0.2rem solid black;*/
    }
    .list-entry {
        padding-left: 2rem;
    }

    .list span {
        font-weight: 600;
    }


    @layer base {
        .filter {
            
            background: white;            
            position: absolute;
            top: 11.2rem;
            text-align: left;
            font-size: 1.2rem;
            line-height: 2rem;
            width: 83.3%;
            left: 8.35%;
            margin: 0 auto 0 auto;
            
            padding: 0;

            height: 2.2rem;

        }

        .filter-wrapper {
            
            padding: 0;
            margin: 0;            
        }

        .filter-wrapper-top {
            @apply grid grid-cols-5;            
            border-bottom: 0.2rem solid black;                  
        }

        .filter-wrapper-bottom {            
            position: relative;
            border-bottom: 0.2rem solid black;
            border-top: 0.2rem solid black;
            margin-top: -0.2rem;
            background: white;
        }

        .filter-small {
            top: 2.6rem;
        }

        .filter-big {
            top: 11.2rem;
        }

        .filter-left {            
            font-weight: bold;
        }

        .filter-right {
            
        }

    }

    .filterslist {
        @apply: bg-gray-200;
        padding-left: 1rem;        
        display: inline-block;
    }

    .filter-entry {
        
        display: inline-block;        
        margin: 0 auto;
        padding: 0 .1rem 0 .1rem;
        
        
    }

    .filter-entry ul {
        
        display: none;
    }

    .filter-entry-visible ul {
        
        
        display: inline-block;
    }

    .menu {
       
        @apply flex col-span-3;

    }

    .menu-init {}

    .menu-std {}

    .menu-invisible {
        
        display: none;
        
    }

    .menu-visible {

        
        display: inline;
        


    }


}
