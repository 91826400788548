@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/css/image-gallery.css";


html {
    /*font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
    font-size:1em;
    
}

* {
  
  /* font-weight: 400;
   font-size:0.85em; */
}


@layer base {
.main-content {
  @apply lg:w-5/6 lg:mx-auto mx-5;
  padding-top: 16rem;

}
}

a:link {
  
}

body {
  margin: 0;
  padding: 0;
}

.info-big {
  font-size: 44pt;
  font-weight: 300;
  text-transform: uppercase;
}


@layer base {
  .info {
    @apply mx-auto bg-gray-100 justify-center flex;
  }
}

@layer base {

  .aboutimage {border: 0px solid black; max-width: 30%;  height: auto;}

  .aboutbox span {padding:0; margin: 0; font-weight:bold;}
}

/* https://www.sanity.io/guides/import-svg-files-in-react */
.svg {
  /* margin: auto;
  text-align:center; */
  width: 2em;
  height: 2em;
  background: gray;

}

.path {

  stroke-dasharray: 50;
  stroke-dashoffset: 12;

  stroke: black;
  stroke-width: 3;
  fill: none;

  stroke-width: 4;
  animation: load 5s linear infinite;
  transform-origin: center;
}


@keyframes load {
  0% {
    stroke-dashoffset: 50;
  }

  50% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -50;
  }
}

.adm-actions {

}

.adm-edit {
    z-index:3;
    position: relative;
}


.dialogue {
  z-index: 3 !important;
  position: relative;
  background-color: #EEEEEE;
  padding: 50px;
  opacity: 0.9;
}

.post-main h1 {
  font-size: 28pt;
  margin-bottom: 10px;
}

.post-main h2 {
  font-size: 20pt;
  margin-bottom: 30px;
}

@layer components {

  .post-main .post-title input {
    @apply
    /*form-control*/
    block
    w-full
    px-4
    py-2
    text-xl
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    my-2
    /*focus: text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none*/
    ;
  }

  .post-main .post-subtitle input {
    @apply
    /*form-control*/
    block
    w-full
    px-4
    py-2
    text-xl
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    my-2
    /*focus: text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none*/
    ;
  }

  .post-main .post-description textarea {
    @apply
    /*form-control*/
    block
    w-full
    px-4
    py-2
    text-xl
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    my-2
    /*focus: text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none*/


    ;
    height: 30rem;
  }

  .dropzone {
    @apply
    bg-gray-200
    ;
    border: 2px dashed black;
  }

  .progress-bar {
    @apply
    bg-blue-200
    transition-all ease-out duration-1000
    p-2
    rounded
  }

  .post-preview, .post-save, .post-saved, .btn {
      @apply
      inline-block
      px-6
      m-3
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xl
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700
      hover:shadow-lg
      focus:bg-blue-700
      focus:shadow-lg
      focus:outline-none
      focus:ring-0
      active:bg-blue-800
      active:shadow-lg
      transition duration-150
      ease-in-out
      ;
  }
  .post-save {
    @apply bg-blue-800
    hover:scale-110
    ;
  }
  .post-saved {
    @apply bg-blue-200
    hover:scale-110
    ;
  }

  .btn {
    @apply bg-blue-600;
  }



}



@layer base {

  .aboutbox {}
  .aboutcv {
    @apply mt-6;
  }
  .aboutentry {
    @apply grid grid-cols-4 gap-4;

  }
  .aboutdate {
    @apply col-span-1 p-2 text-xl font-bold;
    font-size:1.0em;
  }

  .aboutdescription {
    @apply col-span-3 p-2 text-xl;
    font-size:1.0em;
  }
}

@layer base {
  .footer {
    @apply grid grid-cols-2 m-6;

  }
.footerleft {
  @apply my-auto;
}
.footerright{
  @apply text-right;
}

.impressumsocialicon {

}
}

@layer base {
  .databox h1 {font-size:2em; padding-bottom: 1em;}
  .databox h2 {font-size:1.5em; padding-top: 2em; padding-bottom: 0.5em;}
  .databox h3 {font-size:1.2em; padding-top: 1em; padding-bottom: 0.5em;}
}

@layer base {

.post-entry {



  background: white;
  padding: .5rem;
  margin: .5rem;
  position: relative;
  border: solid 0.1rem white;
}

.post-entry:hover {

  border: solid 0.1rem black;
}

.post-entry:hover::before,
.post-entry:hover::after {

  position: absolute;
  background: inherit;
  content: '';
  z-index: 1;
}
.post-entry:hover::before {
  width: calc(100% - 2rem);
  left: calc(0% + 1rem);
  height: calc(100% + 0.4rem);
  top: -.2rem;
}
.post-entry:hover::after {
  height: calc(100% - 2rem);
  left: -0.2rem;
  width: calc(100% + 0.4rem);
  top: calc(0% + 1rem);
}
.imagehoster {
  position: relative; z-index: 2; /* ensure any child elements sit above pseudos */
}



.posts-image {
  height: 11rem;
}

.post-gallery {
    position: absolute;
    top: 110%;
    z-index:2;
    @apply w-full grid grid-cols-2;
}

.post-gallery img {
  background: white;
  scale: 2;
}

.div-align-right {
  margin: 0 0 0 auto;
}

.img-rotate180 {
  transform: rotate(180deg);
}

.image-gallery-image {
  width: 100px;
  height: 320px;
}

.img-control {
  padding-top:1rem;
  padding-bottom: 2rem;
}

.img-control div {
  margin: 0 auto 0 auto;
}

.post-image-gallery {
  padding-bottom: 5rem;
}

.hr-division {
  margin: 1rem 5rem 1rem 5rem;
}

.mydialogue {
	background: white;
	position: absolute;
	width: 15rem;
	top: -1rem;
	left: 1rem;
	z-index: 5000000000;
	border: 2px solid black;
	border-radius: 20px;
	padding: 1rem;
}

}
